import { Button, Col, Input, Layout, Menu, Row } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelectorRoot } from "../../store/store";
import SYSTEM_CONSTANTS from "../../common/constants";
import { getConfigs, setIsShowHome } from "../../store/controls/Config.slice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getProductions, getProductionsSuccess, setSearch } from "../../store/controls/Productions.slice";
import { SearchOutlined } from '@ant-design/icons';
import SearchIcon from '../../image/icon/search.svg';
import './AppHeader.css'
var SearchGlobal = '';
var searchTimeout: any;
const AppHeader = () => {
  const dispatch = useDispatch();
  const { ConfigsData } = useSelectorRoot(state => state.configs);
  const { pagination, searchProduction, menuId } = useSelectorRoot(state => state.productions);
  const { withScreens } = useSelectorRoot(state => state.menus);
  const [logo, setLogo] = useState(ConfigsData?.data.find(x => x.attributes.Code == 'logo'));
  const [phone, setPhone] = useState(ConfigsData?.data.find(x => x.attributes.Code == 'phone'));
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    dispatch(getConfigs());
  }, []);
  useEffect(() => {
    setLogo(ConfigsData?.data.find(x => x.attributes.Code == 'logo'));
    setPhone(ConfigsData?.data.find(x => x.attributes.Code == 'phone'));
  }, [ConfigsData]);
  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    let text = event.target.value;
    text = text.trim();
    setSearchText(text);
    SearchGlobal = text;
    if (!searchTimeout) {

      searchTimeout = setTimeout(() => {
        dispatch(getProductions({
          page: pagination?.page,
          size: pagination?.pageSize,
          search: SearchGlobal,
          menuId
        }));
        clearTimeout(searchTimeout);
        searchTimeout = null;
      }, 500)
    }
  }
  return (
    <Row className="header-app" style={{alignItems: 'center'}}>
      {
        logo?.attributes.Image?.data[0] ? (
          <Link to="/" style={{ display: 'inline-block' }} onClick={() => {
            dispatch(getProductionsSuccess(null));
            dispatch(setSearch(''));
            dispatch(setIsShowHome(true));
          }}>
            <img
              src={SYSTEM_CONSTANTS.HOST + logo?.attributes.Image?.data[0].attributes.url}
              alt="Logo"
              style={{ height: '60px', objectFit: 'cover' }}
            />
          </Link>
        ) : null
      }
      <div style={{ flex: 1 }}></div>
      {showSearch ?
        <Input.Search
          value={searchText}
          placeholder="Tìm kiếm sẩn phẩm"
          enterButton={
            <Button
              style={{ backgroundColor: '#d8232a' }}
              icon={<SearchOutlined style={{ color: 'white' }} />}
            />}
          onChange={onSearch}
          style={{ maxWidth: '500px', padding: '0 10px', position: withScreens && withScreens < 500 ? 'absolute' : 'relative', top: withScreens && withScreens < 500 ? '50px' : '0px', zIndex: 10000 }}
          autoFocus={true}
          onBlur={() => {
            setShowSearch(false);
          }} />
        : null
      }
      {
        !window.location.pathname.includes('detail') && ((withScreens && withScreens < 500) || !showSearch) ?
        
          <Button
            onClick={() => {
              setShowSearch(!showSearch);
            }}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={SearchIcon} style={{width: 25, height: 25}}></img>
            </Button>
          : null
      }
      <a href={`${phone?.attributes.Value}`} style={{ alignItems: 'center', display: 'flex', color: 'white', fontWeight: 'bold', textDecoration: 'none' }}>
        <img src={SYSTEM_CONSTANTS.HOST + phone?.attributes.Image?.data[0].attributes.url} alt="Logo" style={{ height: '30px', width: '30px', marginRight: '8px' }} />
      </a>
    </Row>
  );
};

export default AppHeader;