import React, { useEffect, useState } from 'react';
import { DatePicker, Layout, message } from 'antd';
import {
  Redirect, Route, RouteProps, Switch
} from 'react-router-dom';
import { RootClaim, RouteObj } from '../Routes';
import { PrivateRoute } from '../../components/Routes';
import { Page } from '../../components/page/Page';
import { WaitOverlay } from '../../components/overlay/WaitOverlay';
import { ControlTable } from '../ControlTable/ControlTable';
import { useSelectorRoot } from '../../store/store';
import SYSTEM_CONSTANTS from '../../common/constants';
import { ProductionScreen } from '../screen/ProductionScreen';

const { Content } = Layout;
export const Main = () => {
  const {MenusData} = useSelectorRoot(state => state.menus);
  const [Routes, setRoutes] = useState<RouteObj[]>([]);
  useEffect(() => {
    setRoutes(MenusData?.data.map(x => ({
      label: x.attributes.Title,
      icon: x.attributes.Icon?.data?.attributes ? `${SYSTEM_CONSTANTS.HOST}${x.attributes.Icon?.data?.attributes?.url}` : '',
      color: 'black',
      rootPermission: [RootClaim.All],
      routeProps: {
        path: x.attributes.router,
        children: <ProductionScreen menuId={x.id}></ProductionScreen>
      },
      sort: x.attributes.Sort,
      id: x.id,
    })) ?? []);
  }, [MenusData]);
  const renderMain = () => {
    var routerProps: {label: string; routerProps:RouteProps; rootPermission?:RootClaim[]}[]   = []
    for (var item of Routes) {
      if (item.items && item.items.length > 0) {
        for(var child of item.items) {
          if (child.routeProps) {
            routerProps.push({
              label: child.label,
              routerProps: child.routeProps,
              rootPermission: child.rootPermission
            });
          }
          for(var childItem of (child?.items ?? [])) {
            if (childItem.routeProps) {
              routerProps.push({
                label: childItem.label,
                routerProps: childItem.routeProps,
                rootPermission: childItem.rootPermission
              });
            }
          }
        }
      }
      if (item.routeProps) {
        routerProps.push({
          label: item.label,
          routerProps: item.routeProps,
          rootPermission: item.rootPermission
        });
      }
    }
    return routerProps.map(({
      label,
      rootPermission,
      routerProps,
    }) => (
      
        <PrivateRoute
        allowedRoles={[...(rootPermission ?? [RootClaim.All])]}
        key={`${label}`}
        label={label}
        {...routerProps}
      />
    ))
  }
  return (
      <Switch>
            <Route exact path="/" render={() => (
           <ControlTable></ControlTable>)} />
            {renderMain()}
          </Switch>
  );
};
