import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination, QueryParams, ResponseData, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import CategorysAPI, { Category } from "../../api/Categorys.api";

interface CategorysState {
    loading: boolean;
    CategorysData: ResponseData<Category> | null
    errorMessage: string | null;
    pagination: Pagination | null | undefined;
}

const initState: CategorysState = {
    loading: false,
    CategorysData: null,
    errorMessage: null,
    pagination: null
};
const CategorysSlice = createSlice({
    name: 'Categorys',
    initialState: initState,
    reducers: {
        getCategorys(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getCategorysSuccess(state, action: PayloadAction<ResponseData<Category> | null>) {
            state.loading = false;
            state.CategorysData = action.payload;
            state.pagination = action.payload?.meta?.pagination;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const Categorys$: RootEpic = (action$) =>
    action$.pipe(
        filter(getCategorys.match),
        switchMap((re) => {
            return CategorysAPI.get().pipe(
                mergeMap((res) => {
                    return [
                        CategorysSlice.actions.getCategorysSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    CategorysSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const CategorysEpics = [Categorys$];
export const {
    getCategorys,
    getCategorysSuccess,
} = CategorysSlice.actions;
export const CategorysReducer = CategorysSlice.reducer;