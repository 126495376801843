import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryParams, ResponseData, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import MenusAPI, { Menu } from "../../api/Menus.api";
import { set } from "date-fns";
import TagsAPI, { Tag } from "../../api/Tags.api";

interface MenusState {
    loading: boolean;
    MenusData: ResponseData<Menu> | null;
    TagsData: ResponseData<Tag> | null;
    withScreens: number | null;
    heightScreens: number | null;
    errorMessage: string | null;
}

const initState: MenusState = {
    loading: false,
    MenusData: null,
    errorMessage: null,
    withScreens: null,
    heightScreens: null,
    TagsData: null,
};
const MenusSlice = createSlice({
    name: 'Menus',
    initialState: initState,
    reducers: {
        getMenus(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getMenusSuccess(state, action: PayloadAction<ResponseData<Menu>>) {
            state.loading = false;
            state.MenusData = action.payload;
        },
        getTags(state, action: PayloadAction<QueryParams>) {
            state.loading = true;
        },
        getTagsSuccess(state, action: PayloadAction<ResponseData<Tag> | null>) {
            state.loading = false;
            state.TagsData = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
        setWithScreens(state, action: PayloadAction<number>) {
            state.withScreens = action.payload;
        },
        setHeightScreens(state, action: PayloadAction<number>) {
            state.heightScreens = action.payload;
        }
    }}
);

const Menus$: RootEpic = (action$) =>
    action$.pipe(
        filter(getMenus.match),
        switchMap((re) => {
            return MenusAPI.get().pipe(
                mergeMap((res: any) => {
                    return [
                        MenusSlice.actions.getMenusSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    MenusSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

const Tags$: RootEpic = (action$) =>
    action$.pipe(
        filter(getTags.match),
        switchMap((re) => {
            return TagsAPI.get(re.payload).pipe(
                mergeMap((res) => {
                    return [
                        MenusSlice.actions.getTagsSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    MenusSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const MenusEpics = [Menus$, Tags$];
export const {
    getMenus,
    getMenusSuccess,
    setWithScreens,
    setHeightScreens,
    getTags,
    getTagsSuccess,
} = MenusSlice.actions;
export const MenusReducer = MenusSlice.reducer;