import { Button, Card, Col, Row, Tabs, Typography } from "antd"
import { Content } from "antd/es/layout/layout"
import { useDispatch } from "react-redux";
import { useSelectorRoot } from "../../store/store";
import { useEffect, useRef, useState } from "react";
import { getCategorys } from "../../store/controls/Categorys.slice";
import { useHistory } from "react-router-dom";
import { getProduction } from "../../store/controls/Productions.slice";
import SYSTEM_CONSTANTS from "../../common/constants";
import PlaceHolder from '../../image/icon/placeholder.svg';
import BackIcon from '../../image/icon/curved-arrow-back-blue-icon.svg';
import { Room } from "../../api/Categorys.api";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

const { Title, Text } = Typography;

export const ProductionDetailScreen = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = new URLSearchParams(history.location.search);
    const productionId = query.get("id");
    const cropperRef = useRef<ReactCropperElement>(null);
    const { CategorysData } = useSelectorRoot(state => state.categorys);
    const { selectedProduction } = useSelectorRoot(state => state.productions);
    const { withScreens, heightScreens } = useSelectorRoot(state => state.menus);
    const [selectedPro, setSelectedPro] = useState(selectedProduction);
    const [selectedRoom, setSelectedRoom] = useState<Room>();
    const [cropData, setCropData] = useState("#");
    const imgRef = useRef<any>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [categorys, setCategorys] = useState(CategorysData?.data.map(x => ({
        ...x.attributes,
        id: x.id,
    })));
    useEffect(() => {
        dispatch(getCategorys());
        dispatch(getProduction({
            id: productionId,
        }))
        const handleLoad = () => {
            if (imgRef.current) {
              setDimensions({
                width: imgRef.current.offsetWidth,
                height: imgRef.current.offsetHeight,
              });
            }
          };
      
          const imgElement = imgRef.current;
          if (imgElement && imgElement.complete) {
            handleLoad();
          } else if (imgElement) {
            imgElement.addEventListener('load', handleLoad);
            return () => imgElement.removeEventListener('load', handleLoad);
          }
    }, []);
    useEffect(() => {
        if (CategorysData && CategorysData?.data.length > 0 && CategorysData?.data[0].attributes.rooms && CategorysData?.data[0].attributes.rooms?.data.length > 0) {
            setSelectedRoom({ ...CategorysData?.data[0].attributes.rooms.data[0].attributes, id: CategorysData?.data[0].attributes.rooms.data[0].id });
        }

        setCategorys(CategorysData?.data.map(x => ({
            ...x.attributes,
            id: x.id,
        })));
    }, [CategorysData])
    useEffect(() => {
        setSelectedPro(selectedProduction);
    }, [selectedProduction])
    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());

        }
    };
    return (
        <Content style={{ padding: '0 0' }}>
            <Row style={{ alignItems: 'center', padding: '10px 20px' }}>
                <Button type="text" onClick={() => {
                    if (history.length > 0)
                        history.goBack()
                    else history.push('/')
                }} icon={<img style={{ width: '100%' }} src={BackIcon} />}></Button>
                <div style={{ flex: 1, textAlign: 'center' }}>
                    <Text style={{fontSize: 20, fontWeight: 'bold'}}>{selectedPro?.Title}</Text>
                </div>
            </Row>
            <Row style={{ padding: '0px 20px' }}>
                <Col style={{ justifyContent: 'center', width: 'calc(100% - 10px)' }}>
                    <Row gutter={16} style={{ justifyContent: 'center', height: withScreens && withScreens > 500 ? '40vh' : '35vh', margin: '0px 0px' }}>
                        <div style={{ justifyContent: 'center', width: '100%', display: 'flex' }}>
                            {/* <div
                                className="img-preview"
                            /> */}
                            <img src={cropData} style={{
                                 maxHeight: withScreens && withScreens > 500 ? 300 : 180, 
                                 width: dimensions.width*4/5, 
                                 height: dimensions.height,
                                 objectFit: 'fill', 
                                 }}></img>
                        </div>

                        <div style={{ justifyContent: 'center', width: '100%', alignItems: 'center', position: 'absolute', display: 'flex' }}>
                            <img
                                ref={imgRef}
                                alt={selectedRoom?.Title}
                                src={`${SYSTEM_CONSTANTS.HOST}${selectedRoom?.Image?.data?.attributes?.url}`}
                                style={{ maxHeight: heightScreens && heightScreens > 1000?  400 : 300, objectFit: 'cover', maxWidth: 800, width: '100%' }}
                            />
                        </div>
                    </Row>
                    <Row gutter={16} style={{ justifyContent: 'center', marginTop: 5}}>
                        <Col style={{ overflow: 'scroll', height: withScreens && withScreens > 500? 'calc(50vh - 150px)' : 'calc(50vh - 50px)' }}>
                            <Row style={{ justifyContent: 'center' }}>
                                <Tabs defaultActiveKey="1" style={{ justifyContent: 'center', width: 800, overflowX: 'scroll' }} items={categorys?.map((x, i) => (
                                    {
                                        key: x.id.toString(),
                                        label: x.Title,
                                        children:
                                            (
                                                <div style={{ height: '200px', overflowX: 'scroll' }}>
                                                    <Row style={{ width: (x.rooms?.data.filter(y => y.attributes != null).length ?? 0) * 220 }}>
                                                        {
                                                            x.rooms?.data.filter(y => y.attributes != null).map(y => (
                                                                <Button
                                                                    style={{ width: 200, height: 150, padding: 0, justifyContent: 'center', margin: '0 10px' }}
                                                                    onClick={() => {
                                                                        setSelectedRoom({ ...y.attributes, id: y.id });
                                                                    }}>
                                                                    <img
                                                                        alt={y.attributes.Title}
                                                                        src={`${SYSTEM_CONSTANTS.HOST}${y.attributes.Image?.data?.attributes?.url}`}
                                                                        style={{ width: 200, height: 150, objectFit: 'cover', }} />
                                                                </Button>

                                                            ))
                                                        }
                                                    </Row>
                                                </div>
                                            ),
                                    }
                                )) ?? []} onChange={(key) => {
                                    let item = categorys?.find(x => x.id.toString() === key);
                                    let room = item?.rooms?.data.find(y => y.attributes != null)
                                    if (room) {
                                        setSelectedRoom({ ...room.attributes, id: room.id });
                                    }
                                }} />
                            </Row>
                            <Row style={{ justifyContent: 'center', margin: '20px 0' }}>
                                <Cropper
                                    style={{ height: 300, width: 300 }}
                                    initialAspectRatio={1}
                                    preview=".img-preview"
                                    src={selectedProduction?.Image ? `${SYSTEM_CONSTANTS.HOST}${selectedPro?.Image?.data?.attributes?.url}` : PlaceHolder}
                                    ref={cropperRef}
                                    viewMode={1}
                                    guides={true}
                                    background={false}
                                    responsive={true}
                                    checkOrientation={false}
                                    crop={getCropData}
                                />
                            </Row>
                        </Col>
                    </Row>

                </Col>
            </Row>

        </Content>
    )
}