import { Card, Col, Input, Layout, Modal, Pagination, Result, Row, Image, Button } from "antd";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getProductions } from "../../store/controls/Productions.slice";
import { useSelectorRoot } from "../../store/store";
import SYSTEM_CONSTANTS from "../../common/constants";
import PlaceHolder from '../../image/icon/placeholder.svg';
import { useHistory } from "react-router-dom";
import { Production } from "../../api/Productions.api";
import { getTags } from "../../store/controls/Menus.slice";
import { use } from "i18next";
import { Tag } from "../../api/Tags.api";
import { FilterOutlined } from '@ant-design/icons';
interface ProductionProps {
    menuId: number;
}

export const ProductionScreen: FC<ProductionProps> = ({ menuId }: ProductionProps) => {
    const dispatch = useDispatch();
    const { ProductionsData, pagination, searchProduction } = useSelectorRoot(state => state.productions);
    const { withScreens, TagsData } = useSelectorRoot(state => state.menus);
    const [current, setCurrent] = useState(pagination?.page ?? 1);
    const [pageSize, setPageSize] = useState(pagination?.pageSize ?? 10);
    const [currentTag, setCurrentTag] = useState<Tag>();
    const [isVisible, setIsVisible] = useState(false);

    const history = useHistory();
    const [images, setImages] = useState(ProductionsData?.data.map(x => ({
        ...x.attributes,
        id: x.id,
    })));
    const [tags, setTags] = useState(TagsData?.data.map(x => ({
        ...x.attributes,
        id: x.id,
    })));

    useEffect(() => {
        dispatch(getProductions({
            page: current,
            size: pageSize,
            menuId,
            tagId: currentTag?.id
        }));
        dispatch(getTags({ menuId }));
    }, []);
    useEffect(() => {
        dispatch(getProductions({
            page: current,
            size: pageSize,
            search: searchProduction ?? '',
            menuId,
            tagId: currentTag?.id
        }));
    }, [current, pageSize, currentTag]);
    useEffect(() => {
        setImages(ProductionsData?.data.map(x => ({
            ...x.attributes,
            id: x.id,
        })));
    }, [ProductionsData]);
    useEffect(() => {
        setTags(TagsData?.data.map(x => ({
            ...x.attributes,
            id: x.id,
        })));
    }, [TagsData]);
    const handleChange = (page: number, pageSize: number) => {
        setCurrent(page);
        setPageSize(pageSize)
    };


    return (
        <Layout style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column', flex: 1 }}>
            <div style={{ width: "100%", padding: '0 20px', }}>
                {withScreens && withScreens > 600 ?
                    <div style={{ width: "100%", overflowX: 'scroll', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                        <Button
                            style={{ margin: '10px 10px 10px 0px', borderColor: !currentTag? 'blue' : '#DADADA', color: !currentTag? 'blue' : '#000000'}}
                            onClick={() => {
                                setCurrent(1);
                                setCurrentTag(undefined);
                            }}
                            key={'all'}
                        >
                            {"Tất cả"}
                        </Button>
                        {
                            tags?.map(x => (
                                <Button
                                    style={{ margin: '10px 10px 10px 0px', borderColor: currentTag == x? 'blue' : '#DADADA', color: currentTag == x? 'blue' : '#000000' }}
                                    onClick={() => {
                                        setCurrent(1);
                                        setCurrentTag(x);
                                    }}
                                    key={x.id}
                                >
                                    {x.Name}
                                </Button>
                            ))
                        }
                    </div>
                    : <div style={{ width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            style={{ margin: '10px 0px 10px 0px' }}
                            onClick={() => setIsVisible(true)}
                            icon={<FilterOutlined />}
                        >
                            {"Chọn danh mục"}
                        </Button>

                    </div>
                }
            </div>
            <div style={{ padding: '10px 20px 20px 20px', 
                height: withScreens && withScreens > 1100 ? 'calc(80vh - 200px)' :  withScreens && withScreens > 500 ? 'calc(80vh - 280px)' : 'calc(80vh - 320px)', 
                overflow: 'scroll', display: 'flex', flexDirection: 'column' }}>
                {
                    images && images.length > 0 ?
                        <Row gutter={[24, 24]}>
                            {images?.map((image, index) => (
                                <Col xl={6} xs={12} sm={12} md={8} lg={6} key={index}>
                                    <Card
                                        hoverable
                                        cover={<Image
                                            src={image.Image?.data?.attributes ? `${SYSTEM_CONSTANTS.HOST}${image.Image?.data?.attributes?.url}` : PlaceHolder}
                                            style={{ height: withScreens && withScreens > 600 ? '20vh' : '10vh', objectFit: 'cover' }}
                                            preview={{
                                                destroyOnClose: true,
                                                imageRender: (origin, info) => (
                                                    <div style={{ width: withScreens && withScreens > 1000 ? '60%' : '100%', justifyContent: 'center', padding: '30px' }}>
                                                        <Image
                                                            width="100%"
                                                            style={{ objectFit: 'cover', maxHeight: '80vh' }}
                                                            preview={false}
                                                            src={info.image.url}
                                                        />
                                                        <div style={{ height: 50 }}></div>
                                                        <Button
                                                            style={{
                                                                backgroundColor: '#d8232a',
                                                                color: 'white',
                                                                fontSize: 20,
                                                                padding: '20px',
                                                                fontWeight: 'bold',
                                                            }}
                                                            onClick={() => {
                                                                if (image.Image?.data?.attributes) {
                                                                    let url = `/detail?id=${image.id}`;
                                                                    history.push(url)
                                                                }
                                                            }}>{'Xem phối cảnh'}</Button>
                                                    </div>
                                                ),
                                                toolbarRender: () => null,
                                            }}
                                        />}
                                        bodyStyle={{
                                            padding: 10,
                                        }}
                                    >
                                        <Card.Meta
                                            title={
                                                <div style={{
                                                    fontSize: 11,
                                                    height: 20,
                                                    overflow: 'hidden',
                                                }}>
                                                    {image.Title}
                                                </div>
                                            }
                                            // description={image.Description}
                                            style={{
                                                fontSize: 11,
                                                height: 20,
                                                overflow: 'hidden',
                                                padding: '0px',
                                            }} />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        : <Row gutter={[16, 16]} style={{ justifyContent: 'center' }}>
                            <Result
                                status="404"
                                title="Xin lỗi!"
                                subTitle="Không tìm thấy sản phẩm nào."
                            />
                        </Row>
                }
            </div>
            <div style={{ textAlign: 'right', padding: '10px', marginRight: withScreens && withScreens > 500 ? '50px' : '0px' }}>
                <Pagination
                    current={current}
                    pageSize={pageSize}
                    total={pagination?.total}
                    showSizeChanger={false}
                    showLessItems={true}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onChange={handleChange}
                />
            </div>
            <Modal
                title="Chọn danh mục"
                visible={isVisible}
                onCancel={() => setIsVisible(false)} // Close modal when clicking outside
                footer={null} // Remove default footer
                style={{ top: '20%' }}
            >
                <Row gutter={16} style={{ maxHeight: '30%', overflowY: 'auto' }}>
                    <Col span={12} key={-1}>
                        <Button
                            style={{ margin: '10px 10px 10px 0px', borderColor: !currentTag ? 'blue' : '#DADADA', color: !currentTag? 'blue' : '#000000' }}
                            onClick={() => {
                                setCurrentTag(undefined);
                                setCurrent(1);
                                setIsVisible(false);
                            }}
                            key={'all'}
                        >
                            {"Tất cả"}
                        </Button>
                    </Col>
                    {
                        tags?.map((x, i) => (
                            <Col span={12} key={i}>
                                <Button
                                    style={{ margin: '10px 10px 10px 0px', borderColor: currentTag == x? 'blue' : '#DADADA', color: currentTag == x? 'blue' : '#000000'}}
                                    onClick={() => {
                                        setCurrentTag(x)
                                        setCurrent(1);
                                        setIsVisible(false);
                                    }}
                                    key={x.id}
                                >
                                    {x.Name}
                                </Button>
                            </Col>
                        ))
                    }
                   
                </Row>

            </Modal>
        </Layout>
    );

}