import { FloatButton } from "antd";
import { useEffect, useState } from "react";
import SYSTEM_CONSTANTS from "../../common/constants";
import { useSelectorRoot } from "../../store/store";
import { getConfigs } from "../../store/controls/Config.slice";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { Config } from "../../api/Configs.api";

export const FloatContact = () => {
    const dispatch = useDispatch();
    const { ConfigsData } = useSelectorRoot(state => state.configs);
    const { withScreens } = useSelectorRoot(state => state.menus);
    const [configButton, setConfigButton] = useState(ConfigsData?.data.filter(x => x.attributes.Type == 'float_button'));
    // const [facebook, setFacebook] = useState(ConfigsData?.data.find(x => x.attributes.Code == 'facebook'));
    // const [zalo, setZalo] = useState(ConfigsData?.data.find(x => x.attributes.Code == 'zalo'));
    // const [phone, setPhone] = useState(ConfigsData?.data.find(x => x.attributes.Code == 'phone'));
    // const [skplus, setSkplus] = useState(ConfigsData?.data.find(x => x.attributes.Code == 'skplus'));
    const [withScreen, setWithScreen] = useState(withScreens);
    useEffect(() => {
        // setFacebook(ConfigsData?.data.find(x => x.attributes.Code == 'facebook'));
        // setZalo(ConfigsData?.data.find(x => x.attributes.Code == 'zalo'));
        // setPhone(ConfigsData?.data.find(x => x.attributes.Code == 'phone'));
        // setSkplus(ConfigsData?.data.find(x => x.attributes.Code == 'skplus'));
        setConfigButton(ConfigsData?.data.filter(x => x.attributes.Type == 'float_button'));
    }, [ConfigsData]);
    useEffect(() => {
        setWithScreen(withScreens);
    }, [withScreens]);
    useEffect(() => {
        dispatch(getConfigs());
    }, []);
    const renderItem = (item: { id: number, attributes: Config }) => (
        <FloatButton key={item.id}
            onClick={() => {
                window.open(`${item.attributes.Value}`, '_blank');
            }}
            icon={
                <img
                    alt={item?.attributes.Name ?? ''}
                    src={`${SYSTEM_CONSTANTS.HOST}${item?.attributes.Image?.data[0]?.attributes.url}`}
                    style={{ width: 20, height: 20, objectFit: 'cover', }} />} />
    )
    return (
        <FloatButton.Group shape="circle" style={{ right: 20 }}
            trigger={withScreen && withScreen < 500 ? "click" : undefined}
            icon={<QuestionCircleOutlined style={{ backgroundColor: '#00B4D8', color: 'white', borderRadius: '50%' }} />}
        >
            {
                configButton?.map((item) => {
                    return renderItem(item);
                })
               
            }
            
        </FloatButton.Group>
    )
}