const SYSTEM_CONSTANTS = {
    HOST:'https://admin.skplus.namkhoimedia.io.vn',
    IDENTITY: {
        LOGIN: 'api/auth/local',
        FORGOT: 'users',
        PROFILE: 'api/user/me',
        ROLE: 'api/users-permissions/roles',
    },
    MENU: {
        GET: 'api/menus',
    },
    TAG: {
        GET: 'api/tags',
    },
    PRODUCTION: {
        GET: 'api/productions',
    },
    CONFIG: {
        GET: 'api/configs',
    },
    CATEGORY: {
        GET: 'api/categories',
    }
};

export default SYSTEM_CONSTANTS;
