import {combineReducers} from '@reduxjs/toolkit';
import {combineEpics} from 'redux-observable';
import {BootstrapEpics, bootstrapReducer} from './controls/bootstrap.slice';
import {LoginEpics, loginReducer} from './controls/LoginEpic';
import controlReducer, { ControlEpics } from './slice/controlSlice';
import { MenusEpics, MenusReducer } from './controls/Menus.slice';
import { ProductionsEpics, ProductionsReducer } from './controls/Productions.slice';
import { config } from 'process';
import { ConfigsEpics, ConfigsReducer } from './controls/Config.slice';
import { CategorysEpics, CategorysReducer } from './controls/Categorys.slice';

const rootReducer = combineReducers({
    login: loginReducer,
    bootstrap: bootstrapReducer,
    control: controlReducer,
    menus: MenusReducer,
    productions: ProductionsReducer,
    configs: ConfigsReducer,
    categorys: CategorysReducer
});

export const rootEpic = combineEpics(
    ...LoginEpics, 
    ...BootstrapEpics, 
    ...ControlEpics, 
    ...MenusEpics,
    ...ProductionsEpics,
    ...ConfigsEpics,
    ...CategorysEpics
);
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
