import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination, QueryParams, ResponseData, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import ProductionsAPI, { Production } from "../../api/Productions.api";
import { set } from "date-fns";

interface ProductionsState {
    loading: boolean;
    ProductionsData: ResponseData<Production> | null
    errorMessage: string | null;
    pagination: Pagination | null | undefined;
    selectedProduction: Production | null;
    searchProduction: string | null;
    menuId: number | null;
}

const initState: ProductionsState = {
    loading: false,
    ProductionsData: null,
    errorMessage: null,
    pagination: null,
    selectedProduction: null,
    searchProduction: '',
    menuId: null,
};
const ProductionsSlice = createSlice({
    name: 'Productions',
    initialState: initState,
    reducers: {
        getProductions(state, action: PayloadAction<QueryParams>) {
            state.loading = true;
        },
        getProductionsSuccess(state, action: PayloadAction<ResponseData<Production> | null>) {
            state.loading = false;
            state.ProductionsData = action.payload;
            state.pagination = action.payload?.meta?.pagination;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
        getProduction(state, action: PayloadAction<QueryParams>) {
            state.loading = true;
        },
        getProductionSuccess(state, action: PayloadAction<ResponseData<Production> | null>) {
            state.loading = false;
            state.selectedProduction = action.payload && action.payload.data.length > 0? {...action.payload?.data[0].attributes, id: action.payload.data[0].id} : null;
            
        },
        setSearch(state, action: PayloadAction<string>) {
            state.searchProduction = action.payload;
        },
        setMenuId(state, action: PayloadAction<number>) {
            state.menuId = action.payload;
        }
    }}
);

const Productions$: RootEpic = (action$) =>
    action$.pipe(
        filter(getProductions.match),
        switchMap((re) => {
            const query = re.payload;
            return ProductionsAPI.get(query).pipe(
                mergeMap((res) => {
                    return [
                        ProductionsSlice.actions.getProductionsSuccess(res),
                        ProductionsSlice.actions.setMenuId(query.menuId),
                        ProductionsSlice.actions.setSearch(query.search ?? ''),
                    ];
                }),
                catchError((e: AjaxError) => [
                    ProductionsSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

const GetProduction$: RootEpic = (action$) =>
    action$.pipe(
        filter(getProduction.match),
        switchMap((re) => {
            const query = re.payload;
            return ProductionsAPI.get(query).pipe(
                mergeMap((res) => {
                    return [
                        ProductionsSlice.actions.getProductionSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    ProductionsSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const ProductionsEpics = [Productions$, GetProduction$];
export const {
    getProductions,
    getProductionsSuccess,
    getProduction,
    getProductionSuccess,
    setMenuId,
    setSearch,
} = ProductionsSlice.actions;
export const ProductionsReducer = ProductionsSlice.reducer;