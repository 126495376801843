import React, { useEffect, useState } from 'react';
import { Layout, Typography, Image, Row, Col, Button } from 'antd';
import { PhoneOutlined, EnvironmentOutlined } from '@ant-design/icons';
import './CompanyFooter.css';
import bocongthuong from '../../image/icon/bo-cong-thuong.png';
import { useSelectorRoot } from '../../store/store';
import { useDispatch } from 'react-redux';
import { getConfigs } from '../../store/controls/Config.slice';

const { Footer } = Layout;
const { Text, Title } = Typography;

const CompanyFooter = () => {
  const dispath = useDispatch();
  const { ConfigsData } = useSelectorRoot(state => state.configs);
  const [phone, setPhone] = useState(ConfigsData?.data.find(x => x.attributes.Code == 'phone'));
  const [footer, setFooter] = useState(ConfigsData?.data.find(x => x.attributes.Code == 'footer'));
  useEffect(() => {
    dispath(getConfigs());
  }, []);
  useEffect(() => {
    setPhone(ConfigsData?.data.find(x => x.attributes.Code == 'phone'));
    setFooter(ConfigsData?.data.find(x => x.attributes.Code == 'footer'));
  }, [ConfigsData]);
  return (
    <Footer className="custom-footer">
      <Row className="footer-content">
        <Col xl={12} xs={24} sm={24}>
          <div className="footer-address">
            <strong>CÔNG TY TNHH SẢN XUẤT, THƯƠNG MẠI VÀ DỊCH VỤ NHỰA SK</strong>
            <div style={{backgroundColor: 'white', height: 2, marginBottom: 5, width: '70%'}}></div>
            {
              footer?.attributes.Data?.address.map((value: string, index: number) => {
                return <div><EnvironmentOutlined style={{marginRight: 5}} />{value}</div>
              })
            }
            
          </div>
        </Col>
        <Col xl={4} xs={24} sm={24} >
          <Row>
          <Col xl={24} xs={12} sm={12} >
            <div onClick={() => {
              window.open(`${phone?.attributes.Value}`, '_blank');
            }} style={{borderWidth: 2, borderColor: 'white', borderRadius: 10, borderStyle: 'solid', padding: 10, marginBottom: 10, display: 'flex', justifyContent: 'center', width: 120}}>
            <PhoneOutlined style={{ marginRight: '8px' }} />
            {phone?.attributes.Description}</div>
            </Col>
          <Col xl={24} xs={12} sm={12}>
          <img src={bocongthuong}  width={120}   />
          </Col>
          </Row>
        </Col>
      </Row>
      
    </Footer>
  );
};

export default CompanyFooter;
