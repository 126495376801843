import React, { useEffect, useState } from 'react'
import { Button, Layout, Menu, Typography } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelectorRoot } from '../../store/store'
import { setCollap } from '../../store/slice/controlSlice'
import { RootClaim, RouteObj } from '../Routes'
import CaratRight from 'remixicon-react/ArrowRightSLineIcon'
import CaratLeft from 'remixicon-react/ArrowLeftSLineIcon'
import logo from '../../logo.svg';
import styles from './LeftMenu.module.css';
import HomeBlack from '../../image/icon/smart-home-black.svg';
import { FloatingActionButton } from '../../components/Buttons/FloatingActionButton'
import { getMenus, setWithScreens } from '../../store/controls/Menus.slice'
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import SYSTEM_CONSTANTS from '../../common/constants'
import { ProductionScreen } from '../screen/ProductionScreen'
import { tr } from 'date-fns/locale'
import { on } from 'events'
interface SVGProps {
  $filled?: boolean;
  $colorText?: string;
}
const { Text } = Typography;
const SVG = styled(ReactSVG) <SVGProps>`
  & svg rect{
    stroke: ${(props) => (props.$filled ? '#005BA5' : props.$colorText)};
  };
  & svg ellipse {
    stroke: ${(props) => (props.$filled ? '#005BA5' : props.$colorText)};
  };
  & svg path {
    stroke: ${(props) => (props.$filled ? '#005BA5' : props.$colorText)};
  }
`
export const LeftMenu = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const collapsed = useSelectorRoot(state => state.control.isCollap)
  const { MenusData, withScreens } = useSelectorRoot(state => state.menus);
  const onCollapse = () => { dispatch(setCollap(!collapsed)); };

  const [widthWin, setWitdhWin] = useState(withScreens ?? 1000);
  const navigate = ({ key }: any) => {
    if (!collapsed) {
      if (withScreens && withScreens < 1000) {
        onCollapse();
      }
    }
    history.push(key)
  }

  const [Routes, setRoutes] = useState<RouteObj[]>([]);
  useEffect(() => {
    console.log(MenusData);
    let data: RouteObj[] = MenusData?.data
      .map(x => ({
        label: x.attributes.Title,
        icon: x.attributes.Icon?.data?.attributes ? `${SYSTEM_CONSTANTS.HOST}${x.attributes.Icon?.data?.attributes?.url}` : HomeBlack,
        color: 'black',
        rootPermission: [RootClaim.All],
        routeProps: {
          path: x.attributes.router,
        },
        sort: x.attributes.Sort,
        id: x.id,
      })).sort((a, b) => a.sort > b.sort ? 1 : -1) ?? [];
    setRoutes(data);
  }, [MenusData]);
  useEffect(() => {
    dispatch(getMenus());
  }, []);
  useEffect(() => {
    setWitdhWin(withScreens ?? 1000);
    console.log(withScreens);
    if (withScreens && withScreens < 1000) {
      dispatch(setCollap(true));
    }
  }, [withScreens]);

  const activeKey = (): string => {
    for (var route of Routes) {
      if (window.location.pathname == `${route.routeProps?.path as string}`) {
        return route.routeProps?.path as string;
      }
      for (var child of (route.items ?? [])) {
        if (location.pathname.startsWith(`${child.routeProps?.path as string}`)) {
          return child.routeProps?.path as string;
        }
      }
    }
    return '/';
  }

  const rederItem = (route: RouteObj, index: string) => (
    <Menu.Item
      key={route.routeProps ? (route.routeProps.path as string) : index}
      disabled={!route.routeProps}
      icon={route.icon ? <SVG src={route.icon ?? HomeBlack}
        $filled={activeKey() === route.routeProps?.path}
        $colorText={route.color ?? '#222222AF'}></SVG> : undefined}
      title={(<Text style={{ color: 'white' }}>{route.label}</Text>)}>
      {!collapsed ?
        <Text style={{ marginLeft: route.icon ? 0 : 30, color: activeKey() === route.routeProps?.path as string ? '#005BA5' : route.color }}>{route.label}</Text>
        : <Text></Text>}
    </Menu.Item>
  );
  const render = () => (
    <>
      <FloatingActionButton top="20px" right="-14px" onClick={onCollapse}>
        {collapsed ? (
          <CaratRight style={{ verticalAlign: 'middle' }} />
        ) : (
          <CaratLeft style={{ verticalAlign: 'middle' }} />
        )}
      </FloatingActionButton>
      <Menu
        onSelect={navigate}
        className={styles.LeftMenu}
        selectedKeys={[activeKey()]}
        inlineCollapsed={collapsed}
      >
        {Routes.map((route, index) => (
          <>
            {rederItem(route, index.toString())}
            {route.items && route.items.map(child => (
              rederItem(child, 'child' + index)
            ))}
          </>
        ))}

      </Menu>
    </>
  )

  return (
    widthWin >= 1000 ?
      <Layout.Sider collapsed={collapsed} style={{ backgroundColor: 'white' }} collapsedWidth={50}>
        {render()}
      </Layout.Sider>
      : <Layout.Sider style={{ backgroundColor: 'white' }} collapsedWidth={50} width={50}>
        <Layout.Sider collapsedWidth={50} collapsed={collapsed} style={{ backgroundColor: 'white', height: "100%", position: "absolute", top: 0, left: 0, zIndex: 1000 }}>
          {render()}
        </Layout.Sider>
      </Layout.Sider >
  )
}
