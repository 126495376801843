import SYSTEM_CONSTANTS from '../common/constants';
import { Icon, QueryParams, ResponseData } from '../common/define-types';
import HttpClient from './http-client';
import { AjaxError } from 'rxjs/ajax';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface Tag {
  Name: string;
  id: number;
  Description: string;
  Code: string;
  Color?: string;
  Status: number;
}
export default class TagsAPI {
  static host = SYSTEM_CONSTANTS.HOST;
  static get(query: QueryParams): Observable<ResponseData<Tag> | null> {
    let api = `${TagsAPI.host}/${SYSTEM_CONSTANTS.TAG.GET}?populate=Icon`;
    let menusId = query['menuId'];
    if (menusId) {
      api = `${api}&filters[menus][id][$eq]=${menusId}`;
    }
    return HttpClient.get(api, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).pipe(
      map(
        res => (res as ResponseData<Tag>) || null,
        catchError((e: AjaxError) => throwError(e)),
      ),
    );
  }
}
