import SYSTEM_CONSTANTS from '../common/constants';
import {Icon, ResponseData } from '../common/define-types';
import HttpClient from './http-client';
import {AjaxError} from 'rxjs/ajax';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export interface Room {
  Name: string;
  Description: string;
  Status: boolean;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  Title: string;
  Sort: number;
  id?: number;
  Image?: {
    data: {
        id: number;
        attributes: Icon;
    };
  }
}

export interface Category {
  Name: string;
  Description: string;
  Status: boolean;
  Type: string | null;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  Title: string;
  Sort: number;
  id?: number;
  rooms?: {
    data: {
        id: number;
        attributes: Room;
    }[]
    
  }
}

export default class CategorysAPI {
  static host = SYSTEM_CONSTANTS.HOST;
  static get(): Observable<ResponseData<Category> | null> {
      let api = `${CategorysAPI.host}/${SYSTEM_CONSTANTS.CATEGORY.GET}?populate=rooms&populate=rooms.Image`;
      return HttpClient.get(api, {
          headers: {
              'Content-Type': 'application/json; charset=utf-8',
          },
      }).pipe(
          map(
              res => (res as ResponseData<Category>) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
}
