import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination, QueryParams, ResponseData, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import ConfigsAPI, { Config } from "../../api/Configs.api";
import { set } from "date-fns";

interface ConfigsState {
    loading: boolean;
    ConfigsData: ResponseData<Config> | null
    errorMessage: string | null;
    isShowHome: boolean;
}

const initState: ConfigsState = {
    loading: false,
    ConfigsData: null,
    errorMessage: null,
    isShowHome: true,
};
const ConfigsSlice = createSlice({
    name: 'Configs',
    initialState: initState,
    reducers: {
        getConfigs(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getConfigsSuccess(state, action: PayloadAction<ResponseData<Config> | null>) {
            state.loading = false;
            state.ConfigsData = action.payload;
        },
        setIsShowHome(state, action: PayloadAction<boolean>) {
            state.isShowHome = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const Configs$: RootEpic = (action$) =>
    action$.pipe(
        filter(getConfigs.match),
        switchMap((re) => {
            return ConfigsAPI.get().pipe(
                mergeMap((res) => {
                    return [
                        ConfigsSlice.actions.getConfigsSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    ConfigsSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const ConfigsEpics = [Configs$];
export const {
    getConfigs,
    getConfigsSuccess,
    setIsShowHome
} = ConfigsSlice.actions;
export const ConfigsReducer = ConfigsSlice.reducer;