import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Login } from './Login';
import { useSelectorRoot } from '../store/store';
import { LeftMenu } from './leftMenu/LeftMenu';
import { Main } from './main/Main';
import { useDispatch } from 'react-redux';
import Utils from '../common/Utils';
import { getRoles, setLoginSuccess } from '../store/controls/LoginEpic';
import AppHeader from './screen/AppHeader';
import { ProductionDetailScreen } from './screen/ProductionDetailScreen';
import { FloatContact } from './screen/FloatContact';
import { setHeightScreens, setWithScreens } from '../store/controls/Menus.slice';
import CompanyFooter from './footer/CompanyFooter';

export const Root = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  window.addEventListener('resize', () => {
    var withWindows = Utils.getWindowDimensions().width;
    var heightScreens = Utils.getWindowDimensions().height;
    dispatch(setWithScreens(withWindows ?? 1000));
    dispatch(setHeightScreens(heightScreens ?? 1000));
  });
  useEffect(() => {
    dispatch(setWithScreens(Utils.getWindowDimensions().width ?? 1000));
    dispatch(setHeightScreens(Utils.getWindowDimensions().height ?? 1000));
  }, [])
  return (
    <Layout style={{ height: '100vh' }}>
      <AppHeader></AppHeader>
      {
        location.pathname.includes('detail') ?
          <ProductionDetailScreen></ProductionDetailScreen>
          : <Layout>
            <LeftMenu />
            <Layout>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route path="/" component={Main} />
              </Switch>
            </Layout>
          </Layout>
      }
      <FloatContact></FloatContact>
      {!window.location.pathname.includes('detail') &&<CompanyFooter></CompanyFooter>}
    </Layout>
  );
};

function getWindowDimensions() {
  throw new Error('Function not implemented.');
}
