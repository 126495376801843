import { Layout, Carousel, } from "antd";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getConfigs, setIsShowHome } from "../../store/controls/Config.slice";
import { useSelectorRoot } from "../../store/store";
import SYSTEM_CONSTANTS from "../../common/constants";
import { ProductionScreen } from "../screen/ProductionScreen";

export const ControlTable = () => {
  const dispatch = useDispatch();
  const { ConfigsData, isShowHome } = useSelectorRoot(state => state.configs);
  const { ProductionsData } = useSelectorRoot(state => state.productions);
  const [footer, setFooter] = useState(ConfigsData?.data.find(x => x.attributes.Code == 'footer'));
  const [homePost, setHomePost] = useState(ConfigsData?.data.find(x => x.attributes.Code == 'HomePoster'));
  const [banner, setBnner] = useState(ConfigsData?.data.find(x => x.attributes.Code == 'banner'));
  const [showHome, setShowHome] = useState(true);
  
  useEffect(() => {
    dispatch(getConfigs());
  }, []);
  useEffect(() => {
    setFooter(ConfigsData?.data.find(x => x.attributes.Code == 'footer'));
    setHomePost(ConfigsData?.data.find(x => x.attributes.Code == 'HomePoster'));
    setBnner(ConfigsData?.data.find(x => x.attributes.Code == 'banner'));
   
  }, [ConfigsData]);
  useEffect(() => {
    if(ProductionsData && ProductionsData.data.length >= 0 && isShowHome) {
      dispatch(setIsShowHome(false));
    }
    
  }, [ProductionsData, isShowHome]);
  useEffect(() => {
    setShowHome(isShowHome);
  }, [isShowHome]);
  return (
    <>
    {
      !showHome ?
      <ProductionScreen menuId={0}></ProductionScreen>
      :<Layout style={{ height: '100%', display: 'flex', overflow: 'scroll' }}>
       {
        banner?.attributes.Image?.data ?
          <Carousel autoplay >
            {
              banner?.attributes.Image?.data.map(x => (
                <div key={x.id}>
                  <img src={`${SYSTEM_CONSTANTS.HOST}${x.attributes.url}`} alt="banner" style={{ width: '100%' }} />
                </div>
              ))
            }
          </Carousel>
          : null
      }
     
      <div style={{ flex: 1, padding: '0 50px' }}>
        {homePost?.attributes.Data.content ?
          <div dangerouslySetInnerHTML={{ __html: homePost?.attributes.Data.content }} />
          : null}
      </div>
      <div style={{ textAlign: 'center', backgroundColor: '#f0f0f0' }}>
        {footer?.attributes.Data.footer ?
          <div dangerouslySetInnerHTML={{ __html: footer?.attributes.Data.footer }} />
          : null}
      </div>
      
    </Layout>

    }
    </>
   
  );
}