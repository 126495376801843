import React, { ReactNode } from 'react'
import { RouteProps } from 'react-router-dom'

export const enum RootClaim {
  System = 'System',
  Admin = 'Admin',
  User = 'User',
  All = 'All'
}

export const enum OrgClaim {
  Member = 'Member',
  Admin = 'Admin',
  Superuser = 'SuperUser',
}

export interface RouteObj {
  label: string
  icon?: string
  rootPermission?: RootClaim[]
  routeProps?: RouteProps
  documentTitle?: string;
  items?: RouteObj[];
  color?: string;
  sort?: number;
  id?: number;
}

