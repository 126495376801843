import SYSTEM_CONSTANTS from '../common/constants';
import { Icon, ResponseData } from '../common/define-types';
import HttpClient from './http-client';
import {AjaxError} from 'rxjs/ajax';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export interface Menu {
  Title: string;
  Name: string;
  Category: string;
  id: number;
  Description: string;
  Code: string;
  Sort: number;
  Status: number;
  Icon?: {
    data: {
        id: number;
        attributes: Icon;
    }
  };
  router: string;
}
export default class MenusAPI {
  static host = SYSTEM_CONSTANTS.HOST;
  static get(): Observable<ResponseData<Menu> | null> {
      const api = `${MenusAPI.host}/${SYSTEM_CONSTANTS.MENU.GET}?populate=Icon`;
      
      return HttpClient.get(api, {
          headers: {
              'Content-Type': 'application/json; charset=utf-8',
          },
      }).pipe(
          map(
              res => (res as ResponseData<Menu>) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
  }
}
