import React, { FC, useEffect } from 'react';
import { Col, Layout, LayoutProps, Row, Typography } from 'antd';

import { useDispatch } from 'react-redux';
import { setTitle } from '../../store/slice/controlSlice';

const { Content } = Layout;

const { Text } = Typography;

interface PageProps {
  title?: string;
}

export const Page: FC<LayoutProps & PageProps> = ({
  children,
  title,
  className,
  ...props
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (title) {
      dispatch(setTitle(title));
    }
  }, []);

  return (
    <Layout {...props}>
      <Content style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        <Row style={{ padding: "20px 20px 10px 20px" }}>
          <Text style={{ fontSize: 20, color: "#005BA5", fontWeight: 500 }}>{title}</Text>
        </Row>
        <Row style={{ padding: "0", flex: 1 }}>
          {children}
        </Row>
      </Content>
    </Layout>
  );
};
